import React from 'react';
import PropTypes from 'prop-types';
import { useApiLoading } from 'react-reqq';
import Cookie from 'js-cookie';
import { mask } from 'helper';
import FormInput from 'modules/common/forms/FormInput';
import { showModal } from 'modules/common/components/Modal';
import { RESET_PASSWORD } from '../constants';
import { resetPassword } from '../actions';
// eslint-disable-next-line
import LoginModal from './LoginModal';

function ResetPasswordModal({ number, onClose }) {
  const isLoading = useApiLoading(RESET_PASSWORD, 'put');
  // const isSending = useApiLoading(RESEND_SEND_OTP, 'post');
  // const [isSent, setIsSent] = React.useState(false);
  const [form, setForm] = React.useState({
    token: '',
    password: '',
    password_confirmation: '',
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    const mobile_number = Cookie.get('forgot_password_mobile_number');
    const payload = {
      validation_token: Cookie.get('forgot_password_validation_token'),
      ...form,
    };
    resetPassword(payload, () => {
      showModal({
        content: (close) => (
          <LoginModal mobileNumber={mobile_number} onClose={close} />
        ),
      });
    });
  };

  React.useEffect(() => {
    // Enable navigation prompt
    window.onbeforeunload = function blockReload() {
      return true;
    };
    return () => {
      // Remove navigation prompt
      window.onbeforeunload = null;
    };
  }, []);
  return (
    <form className="px-5 pt-0 pb-5 text-sm" onSubmit={handleSubmit}>
      <div className="mb-2">
        <div className="mb-2 text-center">
          <span className="mr-1">
            A verification SMS has been sent to <br />
            <b>{mask(number) || 'your mobile number'}</b>
          </span>
        </div>
        <div>
          <div className="mb-4">
            <FormInput
              name="token"
              onChange={setForm}
              value={form.token}
              label="6-Digit Code"
            />
          </div>
          <div className="mb-4">
            <FormInput
              name="password"
              onChange={setForm}
              value={form.password}
              label="New Password"
              type="password"
            />
          </div>
          <div className="mb-4">
            <FormInput
              name="password_confirmation"
              onChange={setForm}
              value={form.password_confirmation}
              label="Confirm Password"
              type="password"
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2 mt-4">
        <button className="btn primary" type="submit" disabled={isLoading}>
          Submit
        </button>
        <button
          className="btn light"
          type="button"
          onClick={onClose}
          disabled={isLoading}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

ResetPasswordModal.propTypes = {
  number: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ResetPasswordModal;
