import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';
import InputMask from 'react-input-mask';

const IconCalendar = () => (
  <svg
    className="w-4 h-4"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
    />
  </svg>
);

const formatMask = (format) =>
  format.replace(/DD/g, '99').replace(/MM/g, '99').replace(/YYYY/g, '9999');

const convertToMDY = (v, format) => {
  const d = moment(new Date(v));
  if (isEmpty(v)) return '';
  if (
    d.toString() === 'Invalid date' &&
    !isEmpty(v.replace(/-/g, '').replace(/_/g, '').replace(/\s/g, ''))
  ) {
    return '';
  }
  return d.format(format);
};

const convertToYMD = (v, e, f) => {
  try {
    const d = moment(v, f);
    if (
      d.toString() === 'Invalid date' &&
      !isEmpty(v.replace(/\//g, '').replace(/_/g, '').replace(/\s/g, ''))
    ) {
      e.target.focus();
      return '';
    }
    return d.format('YYYY-MM-DD');
  } catch (err) {
    // do nothing...
    return '';
  }
};

function FormDate({
  label,
  name,
  onChange,
  value,
  icon,
  validation,
  validClassName,
  invalidClassName,
  maxLength,
  type,
  format,
  ...props
}) {
  const [state, setState] = React.useState(convertToMDY(value, format));
  const formattedValue = React.useMemo(() => convertToMDY(value, format), [
    value,
    format,
  ]);

  const isActive = React.useMemo(() => {
    if (isEmpty(state)) return false;
    return true;
  }, [state]);

  const handleKeyPress = (e) => {
    const charCode = typeof e.which === 'undefined' ? e.keyCode : e.which;
    const charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
  };

  React.useEffect(() => {
    setState(formattedValue);
  }, [formattedValue]);

  const handleChange = ({ target }) => {
    setState(target.value);
  };

  React.useEffect(() => {
    const newValue = convertToYMD(state);
    if (state.length === 10 && !isEmpty(newValue)) {
      onChange((form) => ({
        ...form,
        [name]: newValue,
      }));
    }
  }, [name, state, onChange]);

  const handleOnBlur = (e) => {
    const d = moment(new Date(state));
    if (!d.isValid() && !isEmpty(state)) {
      e.target.focus();
    }
  };

  const isValid = React.useMemo(() => {
    if (isEmpty(state)) return true;
    const d = moment(new Date(state));
    return state.length === 10 && d.isValid();
  }, [state]);
  const borderValidation = React.useMemo(() => {
    if (isEmpty(state)) return '';
    const d = moment(new Date(state));
    return state.length === 10 && d.isValid()
      ? validClassName
      : invalidClassName;
  }, [state, validClassName, invalidClassName]);
  return (
    <div
      className={`input-wrapper ${isActive ? 'active' : ''} ${
        icon ? 'with-icon' : ''
      } ${borderValidation}`}
    >
      {icon && <div className="icon">{icon}</div>}
      {!isEmpty(label) && (
        <div className="label">
          <div className="content">
            <span>{label}</span>
            {!isValid && <span className="ml-2 font-bold">INVALID</span>}
          </div>
        </div>
      )}
      <InputMask
        onChange={handleChange}
        value={state}
        type={type}
        mask={formatMask(format)}
        maskChar={null}
        onKeyPress={handleKeyPress}
        onBlur={handleOnBlur}
        {...props}
      />
    </div>
  );
}

FormDate.defaultProps = {
  label: '',
  icon: <IconCalendar />,
  // eslint-disable-next-line
  validation: '',
  validClassName: 'valid',
  invalidClassName: 'invalid',
  maxLength: 0,
  type: 'text',
  format: 'YYYY-MM-DD',
};

FormDate.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.instanceOf(Object),
  validation: PropTypes.string,
  validClassName: PropTypes.string,
  invalidClassName: PropTypes.string,
  maxLength: PropTypes.number,
  type: PropTypes.string,
  format: PropTypes.string,
};

export default React.memo(FormDate);
