import { req } from 'react-reqq';
import Cookie from 'js-cookie';
import { toastSuccess } from 'react-geek-toast';
import * as c from './constants';

export const init = () => {
  const token = Cookie.get('_token');
  req.set(c.AUTHENTICATION, {
    isAuthenticated: !!token,
  });
};

export const login = (payload, onSuccess) => {
  req.post({
    key: c.LOGIN,
    url: '/user/v1/auth',
    payload,
    onSuccess: (res) => {
      const token = res?.response?.token;
      Cookie.set('_token', token, { expires: 7 });
      req.set(c.AUTHENTICATION, {
        isAuthenticated: true,
      });
      if (onSuccess) onSuccess();
    },
  });
};

export const logout = () => {
  Cookie.remove('_token');
  req.set(c.AUTHENTICATION, {
    isAuthenticated: false,
  });
};

export const register = (payload, onSuccess) => {
  req.post({
    key: c.REGISTER,
    url: '/user/v1/register',
    payload,
    onSuccess: (res) => {
      Cookie.set('register_validation_token', res?.response?.validation_token, {
        expires: 7,
      });
      Cookie.set('register_mobile_number', payload.mobile_number, {
        expires: 7,
      });
      if (onSuccess) onSuccess(res);
    },
  });
};

export const resendOTP = (payload, onSuccess) => {
  req.post({
    key: c.RESEND_SEND_OTP,
    url: '/user/v1/activate/resend',
    payload,
    onSuccess: (res) => {
      toastSuccess(res?.response?.message || 'Sent!');
      if (onSuccess) onSuccess();
    },
  });
};

export const registerConfirmOTP = (payload, onSuccess, onError) => {
  req.post({
    key: c.REGISTER_CONFIRM_OTP,
    url: '/user/v1/activate',
    payload,
    onSuccess: (res) => {
      toastSuccess(res?.response?.message || 'Success!');
      Cookie.remove('register_validation_token');
      Cookie.remove('register_mobile_number');
      if (onSuccess) onSuccess(res);
    },
    onError,
  });
};

export const forgotPassword = (payload, onSuccess) => {
  req.post({
    key: c.FORGOT_PASSWORD,
    url: '/user/v1/password/forgot',
    payload,
    onSuccess: (res) => {
      Cookie.set(
        'forgot_password_validation_token',
        res?.response?.validation_token,
        {
          expires: 7,
        }
      );
      Cookie.set('forgot_password_mobile_number', payload.mobile_number, {
        expires: 7,
      });
      if (onSuccess) onSuccess(res);
    },
  });
};

export const resetPassword = (payload, onSuccess, onError) => {
  req.put({
    key: c.RESET_PASSWORD,
    url: '/user/v1/password/reset',
    payload,
    onSuccess: (res) => {
      toastSuccess(res?.response?.message || 'Success!');
      Cookie.remove('forgot_password_validation_token');
      Cookie.remove('forgot_password_mobile_number');
      if (onSuccess) onSuccess(res);
    },
    onError,
  });
};
