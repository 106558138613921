import React from 'react';
import firebase from 'firebase';
import { req, useApiGet } from 'react-reqq';
import { CONFIG, FIREBASE_INSTANCE } from './constants';

export const useFirebase = () => {
  const instance = useApiGet(FIREBASE_INSTANCE, null);
  return instance;
};

export const FirebaseService = () => {
  React.useEffect(() => {
    const fb = firebase.initializeApp(CONFIG);
    req.set(FIREBASE_INSTANCE, fb);
  }, []);
};
