import React from 'react';
import { useApiGet } from 'react-reqq';
import { isEmpty } from 'lodash';
import * as actions from './actions';
import * as c from './constants';

const INIT_STATE = {};

export const InitAuth = () => {
  const auth = useApiGet(c.AUTHENTICATION, INIT_STATE);
  React.useEffect(() => {
    if (!isEmpty(auth)) return;
    actions.init();
  }, [auth]);
  return [typeof auth.isAuthenticated !== 'boolean', auth.isAuthenticated];
};

export const UseAuth = () => {
  const auth = useApiGet(c.AUTHENTICATION, INIT_STATE);
  return auth;
};
