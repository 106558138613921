import React from 'react';
import PropTypes from 'prop-types';
import { useApiLoading } from 'react-reqq';
import Cookie from 'js-cookie';
import { mask } from 'helper';
import FormOtp from 'modules/common/forms/FormOtp';
import { showModal, showAlert } from 'modules/common/components/Modal';
import { REGISTER_CONFIRM_OTP, RESEND_SEND_OTP } from '../constants';
import { registerConfirmOTP, resendOTP } from '../actions';
import LoginModal from './LoginModal';

function OtpModal({ number, onClose }) {
  const isLoading = useApiLoading(REGISTER_CONFIRM_OTP, 'post');
  const isSending = useApiLoading(RESEND_SEND_OTP, 'post');
  const [isSent, setIsSent] = React.useState(false);
  const [form, setForm] = React.useState({
    token: '',
  });
  const handleResend = () => {
    resendOTP({ mobile_number: number }, () => {
      setIsSent(true);
    });
  };
  const handleCancel = () => {
    showAlert({
      content: 'Are you sure you want to cancel?',
      onYes: () => {
        Cookie.remove('register_validation_token');
        Cookie.remove('register_mobile_number');
      },
    });
  };
  React.useEffect(() => {
    if (form.token.length === 6) {
      const mobile_number = Cookie.get('register_mobile_number');
      const payload = {
        validation_token: Cookie.get('register_validation_token'),
        ...form,
      };
      registerConfirmOTP(
        payload,
        () => {
          showModal({
            content: (close) => (
              <LoginModal mobileNumber={mobile_number} onClose={close} />
            ),
          });
        },
        () => {
          setForm({ token: '' });
        }
      );
    }
  }, [form, number, onClose]);
  React.useEffect(() => {
    // Enable navigation prompt
    window.onbeforeunload = function blockReload() {
      return true;
    };
    return () => {
      // Remove navigation prompt
      window.onbeforeunload = null;
    };
  }, []);
  return (
    <div className="px-5 pt-0 pb-5 text-sm">
      <div className="mb-2 text-center">
        <div className="mb-2">
          <span className="mr-1">
            A verification SMS has been sent to <br />
            <b>{mask(number) || 'your mobile number'}</b>
          </span>
        </div>
        <p className="font-bold mb-2">6-Digit Code</p>
        <div className="flex justify-center">
          <FormOtp name="token" onChange={setForm} value={form.token} />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2 mt-4">
        {isSent ? (
          <button className="btn" type="button" disabled>
            Sent
          </button>
        ) : (
          <button
            className="btn secondary"
            type="button"
            disabled={isLoading || isSending}
            onClick={handleResend}
          >
            {isSending ? 'Sending...' : 'Re-send OTP'}
          </button>
        )}
        <button
          className="btn light"
          type="button"
          onClick={handleCancel}
          disabled={isLoading}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

OtpModal.propTypes = {
  number: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OtpModal;
