export const FIREBASE_INSTANCE = 'FIREBASE/instance';

export const CONFIG = {
  apiKey: 'AIzaSyCnY5LklSuZq7G3Y80LKJZ2rvCFg7BTO98',
  authDomain: 'ayos-staging.firebaseapp.com',
  databaseURL: 'https://ayos-staging-default-rtdb.firebaseio.com',
  projectId: 'ayos-staging',
  storageBucket: 'ayos-staging.appspot.com',
  messagingSenderId: '45348455726',
  appId: '1:45348455726:web:d1e1f8ec3d4d164cf866bf',
  measurementId: 'G-NWVVMCR8JK',
};
