import React from 'react';
// import PropTypes from 'prop-types';
import { Community, Home, Bell, Message } from 'modules/common/icons';
import { UseAuth } from 'modules/auth/hooks';
import { handleLogin, handleRegister } from 'modules/auth/func';

function MobileFooter() {
  const auth = UseAuth();
  return (
    <>
      {auth.isAuthenticated ? (
        <div className="fixed bottom-0 left-0 h-16 bg-white w-full grid grid-cols-4 items-center divide-x md:hidden">
          <div className="text-center text-gray-600">
            <div className="relative">
              <Home className="w-6 h-6 inline-block text-gray-500" />
            </div>
            <div className="text-xs">Feed</div>
          </div>
          <div className="text-center text-gray-600">
            <div className="relative">
              <span className="bg-red-500 text-white px-1 text-xxs border border-white rounded-full absolute top-0 ml-4 z-10">
                99+
              </span>
              <Message className="w-6 h-6 inline-block text-gray-500" />
            </div>
            <div className="text-xs">Messages</div>
          </div>
          <div className="text-center text-gray-600">
            <div className="relative">
              <span className="bg-red-500 text-white px-1 text-xxs border border-white rounded-full absolute top-0 ml-4 z-10">
                99+
              </span>
              <Bell className="w-6 h-6 inline-block text-gray-500" />
            </div>
            <div className="text-xs">Notifications</div>
          </div>
          <div className="text-center text-gray-600">
            <div className="relative">
              <Community className="w-6 h-6 inline-block text-gray-500" />
            </div>
            <div className="text-xs">Account</div>
          </div>
        </div>
      ) : (
        <div className="fixed bottom-0 left-0 h-24 bg-white w-full flex md:hidden">
          <div className="m-auto flex flex-col items-center">
            <div className="mb-2">You are not signed in.</div>
            <div className="space-x-4">
              <button
                className="btn primary"
                type="button"
                onClick={handleLogin}
              >
                Login
              </button>
              <button
                className="btn outline-primary"
                type="button"
                onClick={handleRegister}
              >
                Register
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

MobileFooter.propTypes = {};

export default MobileFooter;
