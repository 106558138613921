import React from 'react';
import logo from 'assets/images/logo.svg';
import { Search, Connection, Community, Home } from 'modules/common/icons';
import { Link } from 'react-router-dom';
import HeaderUser from 'modules/auth/components/HeaderUser';
import MobileFooter from './MobileFooter';

const HeaderActions = () => {
  return (
    <>
      <Link
        to="/"
        className="px-6 h-full flex items-center hover:bg-gray-50 border-b-2 border-primary-500"
      >
        <Home className="w-7 h-7" />
      </Link>
      <Link
        to="/"
        className="px-6 h-full flex items-center hover:bg-gray-50 border-b-2 border-transparent"
      >
        <Connection className="w-7 h-7" />
      </Link>
      <Link
        to="/"
        className="px-6 h-full flex items-center hover:bg-gray-50 border-b-2 border-transparent"
      >
        <Community className="w-7 h-7" />
      </Link>
    </>
  );
};

function Header() {
  return (
    <>
      <div className="bg-white shadow h-12 md:h-20">
        <div className="header-container h-full flex items-center">
          <div className="flex items-center h-full justify-center md:justify-start">
            {/* <div className="mr-4 lg:hidden">
              <button className="p-1 focus:outline-none" type="button">
                <span className="block bg-primary-500 h-1 w-8 mb-1 rounded" />
                <span className="block bg-primary-500 h-1 w-8 mb-1 rounded" />
                <span className="block bg-primary-500 h-1 w-8 rounded" />
              </button>
            </div> */}
            <img src={logo} className="mt-2 h-6 md:mt-3 md:h-8" alt="AYOS" />
            <div className="md:flex w-full ml-3 hidden">
              <div className="border flex items-center rounded text-left px-2 py-2 w-full">
                <Search className="text-primary-500 mt-1" />
                <input
                  type="text"
                  className="border-none ml-1 w-full text-sm"
                  placeholder="What are you looking for?"
                />
              </div>
            </div>
          </div>
          <div className="h-full hidden lg:block mx-auto">
            <div className="flex h-full">
              <div className="mx-auto flex">
                <HeaderActions />
              </div>
            </div>
          </div>
          <div className="h-full flex items-center ml-auto">
            <HeaderUser />
          </div>
        </div>
      </div>
      <div className="block md:hidden bg-white shadow h-12">
        <div className="flex justify-center items-center h-full">
          <Link
            to="/"
            className="px-6 h-full flex items-center hover:bg-gray-50 border-b-2 border-transparent"
          >
            <Search className="w-7 h-7 text-primary-500" />
          </Link>
          <HeaderActions />
        </div>
      </div>
      <MobileFooter />
    </>
  );
}

Header.propTypes = {};

export default Header;
