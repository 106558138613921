import UserThumbnail from 'modules/partial/components/UserThumbnail';
import React from 'react';
// import PropTypes from 'prop-types';
import { CheckCircle, Bell, Message } from 'modules/common/icons';
import HeaderButton from './HeaderButton';
import { handleLogin, handleLogout, handleRegister } from '../func';
import { UseAuth } from '../hooks';

const IconLogout = () => (
  <svg
    className="w-4 h-4"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 5l7 7-7 7"
    />
  </svg>
);

function HeaderUser() {
  const auth = UseAuth();
  if (!auth.isAuthenticated) {
    return (
      <div className="md:flex items-center space-x-2 hidden">
        <button className="btn primary" type="button" onClick={handleLogin}>
          Login
        </button>
        <button
          className="btn outline-primary"
          type="button"
          onClick={handleRegister}
        >
          Register
        </button>
      </div>
    );
  }
  return (
    <div className="flex items-center">
      <div className="mr-5 hidden md:block">
        <button
          className="h-8 w-8 border rounded-full flex items-center justify-center relative"
          type="button"
        >
          <span className="bg-red-500 text-white px-1 text-xxs border border-white rounded-full absolute top-0 -mr-8 z-10">
            99+
          </span>
          <Message className="w-4 h-4 inline-block text-gray-500" />
        </button>
      </div>
      <div className="mr-10 hidden md:block">
        <button
          className="h-8 w-8 border rounded-full flex items-center justify-center relative"
          type="button"
        >
          <span className="bg-red-500 text-white px-1 text-xxs border border-white rounded-full absolute top-0 -mr-8 z-10">
            99+
          </span>
          <Bell className="w-4 h-4 inline-block text-gray-500" />
        </button>
      </div>
      <div className="text-sm mr-4 leading-none text-right hidden md:block">
        <div className="mb-1">
          <span className="font-light">Hi </span>
          <span className="font-semibold">Juan!</span>
        </div>
        <div className="text-xs text-center flex items-center">
          <span className="text-gray-600 mr-1">Verified</span>
          <CheckCircle />
        </div>
      </div>
      <div>
        <UserThumbnail />
      </div>
      <div className="ml-4">
        <HeaderButton onClick={handleLogout} icon={<IconLogout />} />
      </div>
    </div>
  );
}

HeaderUser.propTypes = {};

export default HeaderUser;
