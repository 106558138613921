import React from 'react';
import PropTypes from 'prop-types';
import logo from 'assets/images/logo.svg';
import { useApiLoading } from 'react-reqq';
import { ModalClose, showModal } from 'modules/common/components/Modal';
import FormPhoneNumber from 'modules/common/forms/FormPhoneNumber';
// eslint-disable-next-line
import ResetPasswordModal from './ResetPasswordModal';
import { forgotPassword } from '../actions';
import { FORGOT_PASSWORD } from '../constants';

function ForgotPasswordModal({ mobileNumber, onClose }) {
  const isLoading = useApiLoading(FORGOT_PASSWORD, 'post');
  const [form, setForm] = React.useState({
    mobile_number: mobileNumber || '+63',
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    forgotPassword(form, () => {
      showModal({
        className: 'modal-sm',
        content: (close) => (
          <ResetPasswordModal number={form.mobile_number} onClose={close} />
        ),
      });
    });
  };
  return (
    <div className="min-w-sm p-8 pt-10 relative">
      <ModalClose onClose={onClose} />
      <div className="text-center mb-4">
        <img src={logo} className="mx-auto w-40 mb-3" alt="AYOS" />
        <p className="text-sm">
          Let&apos;s build a positive community together!
        </p>
        <div className="text-green-500">Forgot Password</div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <FormPhoneNumber
            name="mobile_number"
            onChange={setForm}
            value={form.mobile_number}
            label="Mobile Number"
          />
        </div>
        <div>
          <button
            className="btn primary w-full"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? 'Please wait...' : 'Continue'}
          </button>
        </div>
      </form>
    </div>
  );
}

ForgotPasswordModal.defaultProps = {
  mobileNumber: '',
};

ForgotPasswordModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  mobileNumber: PropTypes.string,
};

export default ForgotPasswordModal;
