import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import PhoneInput from 'react-phone-input-2';

const IconDeviceMobile = () => {
  return (
    <svg
      className="w-6 h-6"
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
      />
    </svg>
  );
};

function FormPhoneNumber({
  label,
  name,
  onChange,
  value,
  icon,
  validation,
  validClassName,
  invalidClassName,
  maxLength,
  ...props
}) {
  const [country] = React.useState('ph');
  const handleChange = (newValue) => {
    if (maxLength > 0 && `${newValue}`.length > maxLength) return;

    onChange((state) => ({
      ...state,
      [name]: isEmpty(newValue) ? '' : `+${newValue}`,
    }));
  };
  const isActive = React.useMemo(() => {
    if (isEmpty(value)) return false;
    return true;
  }, [value]);
  const isValid = React.useMemo(() => {
    if (isEmpty(value) || isEmpty(validation)) return true;
    const regex = new RegExp(validation);
    return regex.test(value);
  }, [value, validation]);
  const borderValidation = React.useMemo(() => {
    if (isEmpty(value) || isEmpty(validation)) return '';
    const regex = new RegExp(validation);
    return regex.test(value) ? validClassName : invalidClassName;
  }, [value, validation, validClassName, invalidClassName]);
  const otherProps = React.useMemo(
    () => ({
      // pattern: '[0-9]*',
      onKeyPress: (e) => {
        const charCode = typeof e.which === 'undefined' ? e.keyCode : e.which;
        const charStr = String.fromCharCode(charCode);
        if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
      },
    }),
    []
  );
  return (
    <div
      className={`input-wrapper tel-input ${isActive ? 'active' : ''} ${
        icon ? 'with-icon' : ''
      } ${borderValidation}`}
    >
      {icon && <div className="icon">{icon}</div>}
      {!isEmpty(label) && (
        <div className="label">
          <div className="content">
            <span>{label}</span>
            {!isValid && <span className="ml-2 font-bold">INVALID</span>}
          </div>
        </div>
      )}
      <PhoneInput
        country={country}
        onChange={handleChange}
        placeholder=""
        value={value}
        masks={{
          ph: '...-...-....',
          gb: '....-......',
        }}
        priority={{ ph: 0 }}
        enableSearch
        disableDropdown
        countryCodeEditable
        specialLabel=""
        containerClass="w-full"
        searchPlaceholder="Search..."
        inputProps={{
          ...otherProps,
          ...props,
        }}
      />
    </div>
  );
}

FormPhoneNumber.defaultProps = {
  label: '',
  icon: <IconDeviceMobile />,
  validation: '',
  validClassName: 'valid',
  invalidClassName: 'invalid',
  maxLength: 0,
};

FormPhoneNumber.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.instanceOf(Object),
  validation: PropTypes.string,
  validClassName: PropTypes.string,
  invalidClassName: PropTypes.string,
  maxLength: PropTypes.number,
};

export default React.memo(FormPhoneNumber);
