import React from 'react';
import ErrorBoundary from 'modules/partial/containers/ErrorBoundary';
import ToastMarker from 'react-geek-toast';
import { ModalMarker, AlertMarker } from 'modules/common/components/Modal';
import { FirebaseService } from 'modules/firebase/hooks';
import Routes from './Routes';

function App() {
  FirebaseService();
  return (
    <ErrorBoundary>
      <ToastMarker />
      <AlertMarker />
      <ModalMarker />
      <Routes />
    </ErrorBoundary>
  );
}

export default App;
