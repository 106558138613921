export const loadAPI = (id, src) =>
  new Promise((cb) => {
    const fjs = document.getElementsByTagName('script')[0];
    const js = document.createElement('script');
    if (document.getElementById(id)) {
      cb();
      return;
    }

    js.id = id;
    js.src = src;
    js.onload = cb;
    fjs.parentNode.insertBefore(js, fjs);
  });

export const generate = (length = 10) => {
  // const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charset = 'ABCDEFGHIJKLMNOPRSTUVWXYZ0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; i += 1) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const dateToSeconds = (from, to = new Date()) => {
  const seconds = Math.floor((from - to) / 1000);
  return seconds > 0 ? seconds : 0;
};

export const timeElapsed = (from, to = new Date()) => {
  const date = from;
  const date2 = to;

  const seconds = Math.floor((date2 - date) / 1000);

  let interval = seconds / 31536000;
  let val = '';
  if (interval > 1) {
    val = Math.floor(interval);
    if (val < 2) return 'Last year';
    return `${val} years ago`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    val = Math.floor(interval);
    if (val < 2) return 'Last month';
    return `${val} months ago`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    val = Math.floor(interval);
    if (val < 2) return 'Yesterday';
    return `${val} days ago`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    val = Math.floor(interval);
    if (val < 2) return 'An hour ago';
    return `${val} hours ago`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    val = Math.floor(interval);
    if (val < 2) return 'A minute ago';
    return `${val} minutes ago`;
  }
  return 'just now';
  // return `${Math.floor(seconds)} seconds ago`;
};

export const secondsToRemaining = (seconds) => {
  let n = seconds;
  const days = n / (24 * 3600);

  n %= 24 * 3600;
  const hours = n / 3600;

  n %= 3600;
  const minutes = n / 60;

  n %= 60;
  const sec = n;

  return {
    days: Math.floor(days),
    hours: Math.floor(hours),
    minutes: Math.floor(minutes),
    seconds: Math.floor(sec),
  };
};

export const mask = (str) => {
  const reg = /.{8}/;
  return str.replace(reg, (m) => '*'.repeat(m.length));
};
