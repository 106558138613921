import React from 'react';
import { ArrowDown } from 'modules/common/icons';
import PropTypes from 'prop-types';

function HeaderButton({ icon, ...rest }) {
  return (
    <button
      className="h-8 w-8 border rounded-full flex items-center justify-center"
      type="button"
      {...rest}
    >
      <ArrowDown className="w-4 h-4 inline-block text-gray-500" />
    </button>
  );
}

HeaderButton.propTypes = {
  icon: PropTypes.instanceOf(Object).isRequired,
};

export default HeaderButton;
