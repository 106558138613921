import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

const IconChevronRight = () => (
  <svg
    className="w-4 h-4"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 5l7 7-7 7"
    />
  </svg>
);

function FormInput({
  label,
  name,
  onChange,
  value,
  icon,
  validation,
  validClassName,
  invalidClassName,
  maxLength,
  type,
  ...props
}) {
  const handleChange = ({ target }) => {
    if (maxLength > 0 && `${target.value}`.length > maxLength) return;
    onChange((state) => ({
      ...state,
      [name]: target.value,
    }));
  };
  const isActive = React.useMemo(() => {
    if (isEmpty(value)) return false;
    return true;
  }, [value]);
  const isValid = React.useMemo(() => {
    if (isEmpty(value) || isEmpty(validation)) return true;
    const regex = new RegExp(validation);
    return regex.test(value);
  }, [value, validation]);
  const borderValidation = React.useMemo(() => {
    if (isEmpty(value) || isEmpty(validation)) return '';
    const regex = new RegExp(validation);
    return regex.test(value) ? validClassName : invalidClassName;
  }, [value, validation, validClassName, invalidClassName]);
  const otherProps = React.useMemo(() => {
    if (type === 'number')
      return {
        pattern: '[0-9]*',
        onKeyPress: (e) => {
          const charCode = typeof e.which === 'undefined' ? e.keyCode : e.which;
          const charStr = String.fromCharCode(charCode);
          if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
        },
      };
    return {};
  }, [type]);
  return (
    <div
      className={`input-wrapper ${isActive ? 'active' : ''} ${
        icon ? 'with-icon' : ''
      } ${borderValidation}`}
    >
      {icon && <div className="icon">{icon}</div>}
      {!isEmpty(label) && (
        <div className="label">
          <div className="content">
            <span>{label}</span>
            {!isValid && <span className="ml-2 font-bold">INVALID</span>}
          </div>
        </div>
      )}
      <input
        onChange={handleChange}
        value={value}
        type={type}
        {...otherProps}
        {...props}
      />
    </div>
  );
}

FormInput.defaultProps = {
  label: '',
  icon: <IconChevronRight />,
  validation: '',
  validClassName: 'valid',
  invalidClassName: 'invalid',
  maxLength: 0,
  type: 'text',
};

FormInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.instanceOf(Object),
  validation: PropTypes.string,
  validClassName: PropTypes.string,
  invalidClassName: PropTypes.string,
  maxLength: PropTypes.number,
  type: PropTypes.string,
};

export default React.memo(FormInput);
