import React from 'react';
import PropTypes from 'prop-types';
import logo from 'assets/images/logo.svg';
import { useApiLoading } from 'react-reqq';
import { ModalClose, showModal } from 'modules/common/components/Modal';
import FormInput from 'modules/common/forms/FormInput';
import FormDate from 'modules/common/forms/FormDate';
import FormPhoneNumber from 'modules/common/forms/FormPhoneNumber';
import OtpModal from './OtpModal';
import { register } from '../actions';
import { REGISTER } from '../constants';

function RegisterModal({ onClose }) {
  const isLoading = useApiLoading(REGISTER, 'post');
  const [form, setForm] = React.useState({
    code_name: '',
    first_name: '',
    last_name: '',
    birth_date: '',
    mobile_number: '+63',
    password: '',
    password_confirmation: '',
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    register(form, () => {
      showModal({
        className: 'modal-sm',
        content: (close) => (
          <OtpModal number={form.mobile_number} onClose={close} />
        ),
      });
    });
  };
  return (
    <div className="min-w-sm p-8 pt-10 relative">
      <ModalClose onClose={onClose} />
      <div className="text-center mb-4">
        <img src={logo} className="mx-auto w-40 mb-3" alt="AYOS" />
        <p
          className="text-sm"
          onDoubleClick={() => {
            setForm({
              code_name: 'alpha.dev',
              first_name: 'Alpha',
              last_name: 'Multisys',
              birth_date: '1990-01-01',
              mobile_number: '+639277744082',
              password: '123123123',
              password_confirmation: '123123123',
            });
          }}
        >
          Let&apos;s build a positive community together!
        </p>
        <div className="text-green-500">Sign Up</div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <FormInput
            name="code_name"
            onChange={setForm}
            value={form.code_name}
            label="Codename"
          />
        </div>
        <div className="mb-4 grid grid-cols-2 gap-4">
          <div>
            <FormInput
              name="first_name"
              onChange={setForm}
              value={form.first_name}
              label="First Name"
            />
          </div>
          <div>
            <FormInput
              name="last_name"
              onChange={setForm}
              value={form.last_name}
              label="Last Name"
            />
          </div>
        </div>
        <div className="mb-4">
          <FormDate
            name="birth_date"
            onChange={setForm}
            value={form.birth_date}
            label="Birthday (MM/DD/YYYY)"
            format="MM/DD/YYYY"
          />
        </div>
        <div className="mb-4">
          <FormPhoneNumber
            name="mobile_number"
            onChange={setForm}
            value={form.mobile_number}
            label="Mobile Number"
          />
        </div>
        <div className="mb-4">
          <FormInput
            name="password"
            onChange={setForm}
            value={form.password}
            label="Password"
            type="password"
          />
        </div>
        <div className="mb-4">
          <FormInput
            name="password_confirmation"
            onChange={setForm}
            value={form.password_confirmation}
            label="Confirm Password"
            type="password"
          />
        </div>
        <div>
          <button
            className="btn primary w-full"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? 'Please wait...' : 'Continue'}
          </button>
        </div>
      </form>
    </div>
  );
}

RegisterModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default RegisterModal;
