import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Splash from 'modules/partial/containers/Splash';
import Landing from 'modules/partial/containers/Landing';
import ComingSoon from 'modules/partial/containers/ComingSoon';
import PageNotFound from 'modules/partial/containers/PageNotFound';
import Logout from 'modules/auth/containers/Logout';
import { InitAuth, UseAuth } from 'modules/auth/hooks';

const PrivateRoute = (props) => {
  const auth = UseAuth();
  if (auth.isAuthenticated) {
    return (
      <Route {...props}>
        <Logout />
      </Route>
    );
  }
  return <Route {...props} />;
};

function Routes() {
  const [isReady] = InitAuth();
  if (isReady) return <Splash />;
  return (
    <Switch>
      <Route exact path="/" component={ComingSoon} />
      <Route exact path="/" component={Landing} />
      <PrivateRoute path="/logout">
        <Redirect to="/" />
      </PrivateRoute>
      <Route component={PageNotFound} />
    </Switch>
  );
}

Routes.propTypes = {};

export default Routes;
