import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function FormOtp({ name, value, onChange, count, ...rest }) {
  const inputRef = useRef();
  const keepFocus = () => {
    try {
      inputRef.current.focus();
    } catch (err) {
      // do nothing...
    }
  };
  useEffect(() => {
    keepFocus();
  }, []);

  const handleChange = ({ target }) =>
    value.length < 6 &&
    onChange((state) => ({
      ...state,
      [name]: target.value,
    }));

  const pinNumberStyle = `w-8 text-center h-12 text-4xl mr-2 font-base border-b-2 text-gray-700`;
  return (
    <div className="flex text-left items-left justify-left">
      <input
        type="number"
        name={name}
        className="absolute opacity-0"
        onBlur={keepFocus}
        ref={inputRef}
        value={value}
        onChange={handleChange}
        inputMode="numeric"
        pattern="[0-9]*"
        autoComplete="one-time-code"
        {...rest}
      />
      <div className="flex items-center">
        {Array.from({ length: count }, (item, key) => key).map((item, i) => (
          <div
            key={item}
            className={`${pinNumberStyle} ${
              value.length === item
                ? 'border-gray-500 bg-light4 animate-pulse'
                : 'border-gray-500'
            }`}
          >
            {value[i] || ''}
          </div>
        ))}
      </div>
    </div>
  );
}

FormOtp.defaultProps = {
  value: null,
  count: 6,
};

FormOtp.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(),
  ]),
  count: PropTypes.number,
};

export default FormOtp;
