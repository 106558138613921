import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function PageNotFound() {
  return (
    <>
      <div>
        Page Not Found.{' '}
        <Link className="underline" to="/">
          Go Back Home
        </Link>
      </div>
    </>
  );
}

PageNotFound.propTypes = {};

export default PageNotFound;
