/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';

export const IconClock = (props) => (
  <svg
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    className={`${props.className ? ` ${props.className}` : ''}`}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);
export const ArrowDown = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    className={`fill-current${props.className ? ` ${props.className}` : ''}`}
  >
    <path fill="none" d="M0 0h20v20H0z" />
    <g fill="none" strokeLinecap="round" strokeLinejoin="round">
      <path d="M10.659 12.924a1 1 0 01-1.317 0L4 8.253A1 1 0 014.661 6.5h10.678A1 1 0 0116 8.253z" />
      <path
        d="M10 13.171c.235 0 .47-.082.659-.247l5.338-4.671c.695-.608.265-1.753-.658-1.753H4.66c-.923 0-1.353 1.145-.658 1.753l5.339 4.67a.998.998 0 00.658.248m0 2a3 3 0 01-1.976-.742L2.686 9.758a2.976 2.976 0 01-.833-3.313A2.976 2.976 0 014.661 4.5H15.34c1.262 0 2.364.763 2.808 1.945a2.976 2.976 0 01-.833 3.313l-5.338 4.671a3 3 0 01-1.976.742z"
        fill="#2c9b14"
      />
    </g>
  </svg>
);
export const Search = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    className={`fill-current${props.className ? ` ${props.className}` : ''}`}
  >
    <path d="M15.676 14.114l-3.377-3.383A6.78 6.78 0 006.784 0 6.785 6.785 0 00-.001 6.784 6.776 6.776 0 0010.73 12.3l3.382 3.382a1.106 1.106 0 101.564-1.564zm-12.125-4.1a4.572 4.572 0 113.232 1.339 4.543 4.543 0 01-3.232-1.336z" />
  </svg>
);
export const Community = (props) => (
  <svg
    width={26.313}
    height={24}
    viewBox="0 0 26.313 24"
    className={`fill-current${props.className ? ` ${props.className}` : ''}`}
  >
    <g fill="none">
      <path d="M1.158 0h24v24h-24z" />
      <g
        transform="rotate(-30 -2673.547 3002.953)"
        stroke="#2c9b14"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <circle
          cx={6.011}
          cy={6.011}
          r={6.011}
          transform="translate(1138.5 1750.121)"
        />
        <circle
          cx={6.011}
          cy={6.011}
          r={6.011}
          transform="translate(1146.844 1749.983)"
        />
      </g>
    </g>
  </svg>
);
export const Home = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    className={`${props.className ? ` ${props.className}` : ''}`}
  >
    <g fill="none">
      <path d="M0 0h24v24H0z" />
      <path
        d="M9.881 20.861H5.82v-6.935H1.281L12 3.142l10.719 10.784h-4.73v6.935h-4.055v-4.895H9.881v4.9z"
        stroke="#2c9b14"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </g>
  </svg>
);
export const Connection = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    className={`${props.className ? ` ${props.className}` : ''}`}
  >
    <g transform="translate(1.867 4.133)" fill="none">
      <path d="M-1.867-4.133h24v24h-24z" />
      <circle
        cx={3.5}
        cy={3.5}
        r={3.5}
        transform="translate(3.766 .5)"
        stroke="#2c9b14"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="M14.926 15.735H0a7.62 7.62 0 017.463-5.339 7.619 7.619 0 017.462 5.338z"
        stroke="#2c9b14"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        stroke="#2c9b14"
        strokeLinecap="round"
        strokeWidth={2}
        d="M17.266 0v6M20.266 3h-6"
      />
    </g>
  </svg>
);
export const Message = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    className={`${props.className ? ` ${props.className}` : ''}`}
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <g fill="none" stroke="#2c9b14">
      <path
        d="M5.258 3.656h13.483a4.036 4.036 0 014.149 3.912v4.888a4.036 4.036 0 01-4.149 3.912h-4l-2.642 3.973-2.9-3.973H5.258a4.036 4.036 0 01-4.149-3.912V7.568a4.036 4.036 0 014.149-3.912z"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <g transform="translate(7.109 9.281)">
        <circle cx={1} cy={1} r={1} stroke="none" />
        <circle cx={1} cy={1} r={0.5} />
      </g>
      <g transform="translate(11.109 9.281)">
        <circle cx={1} cy={1} r={1} stroke="none" />
        <circle cx={1} cy={1} r={0.5} />
      </g>
      <g transform="translate(15.109 9.281)">
        <circle cx={1} cy={1} r={1} stroke="none" />
        <circle cx={1} cy={1} r={0.5} />
      </g>
    </g>
  </svg>
);
export const Bell = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    className={`${props.className ? ` ${props.className}` : ''}`}
  >
    <g fill="none">
      <path d="M0 0h24v24H0z" />
      <path
        d="M18.693 18.786l-1.158-1.992a6.073 6.073 0 01-.817-3.047V12a4.864 4.864 0 00-3.37-4.671v-1.25a1.373 1.373 0 00-1.347-1.393 1.373 1.373 0 00-1.348 1.393v1.25A4.864 4.864 0 007.283 12v1.747a6.077 6.077 0 01-.816 3.046l-1.159 1.993a.358.358 0 000 .351.334.334 0 00.293.177h12.803a.336.336 0 00.293-.176.36.36 0 00-.004-.352z"
        stroke="#2c9b14"
        strokeWidth={2}
      />
    </g>
  </svg>
);
export const CheckCircle = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    className={`${props.className ? ` ${props.className}` : ''}`}
  >
    <g transform="translate(-186 -4646)">
      <circle
        cx={8}
        cy={8}
        r={8}
        transform="translate(186 4646)"
        fill="#00c781"
      />
      <path
        d="M197.858 4651.438l-5 5-1.857-1.855"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
      />
    </g>
  </svg>
);
export const Birthday = (props) => (
  <svg
    width={34}
    height={34}
    viewBox="0 0 34 34"
    className={`${props.className ? ` ${props.className}` : ''}`}
  >
    <g fill="none">
      <path d="M0 0h34v34H0z" />
      <g
        transform="translate(-1717 -1845.469)"
        stroke="#2c9b14"
        strokeWidth={2}
      >
        <path
          d="M1724 1868h20v8a2 2 0 01-2 2h-16a2 2 0 01-2-2v-8h0z"
          strokeLinejoin="round"
        />
        <rect
          width={24}
          height={8}
          rx={3}
          transform="translate(1722 1860)"
          strokeLinejoin="round"
        />
        <path
          d="M1733 1855h2a2 2 0 012 2v3h0-6 0v-3a2 2 0 012-2z"
          strokeLinejoin="round"
        />
        <path d="M1734 1846.938c1.1 0 2 2.545 2 3.65a2 2 0 11-4 0c0-1.105.9-3.65 2-3.65z" />
      </g>
    </g>
  </svg>
);
export const CommunityAdd = (props) => (
  <svg
    width={45}
    height={45}
    viewBox="0 0 45 45"
    className={`${props.className ? ` ${props.className}` : ''}`}
  >
    <g fill="none">
      <path d="M1.066 0h45v45h-45z" />
      <g stroke="#2c9b14" strokeLinecap="round" strokeWidth={2}>
        <path d="M8.485 18.842a10 10 0 110 14.142 10 10 0 010-14.142z" />
        <path d="M14.116 12.017a10 10 0 110 14.142 10 10 0 010-14.142zM40.721 8.945v10M45.721 13.945h-10" />
      </g>
    </g>
  </svg>
);
