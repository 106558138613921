import React from 'react';
import PropTypes from 'prop-types';
import logo from 'assets/images/logo.svg';
import { useApiLoading } from 'react-reqq';
import { ModalClose, showModal } from 'modules/common/components/Modal';
import FormPhoneNumber from 'modules/common/forms/FormPhoneNumber';
import FormInput from 'modules/common/forms/FormInput';
// eslint-disable-next-line
import ForgotPasswordModal from './ForgotPasswordModal';
import { login } from '../actions';
import { LOGIN } from '../constants';

function LoginModal({ mobileNumber, onClose }) {
  const isLoading = useApiLoading(LOGIN, 'post');
  const [form, setForm] = React.useState({
    mobile_number: mobileNumber || '+63',
    password: '',
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    login(form, onClose);
  };
  const handleForgotPassword = (e) => {
    e.preventDefault();
    showModal({
      content: (close) => (
        <ForgotPasswordModal
          mobileNumber={form.mobile_number}
          onClose={close}
        />
      ),
    });
  };
  return (
    <div className="min-w-sm p-8 pt-10 relative">
      <ModalClose onClose={onClose} />
      <div className="text-center mb-4">
        <img src={logo} className="mx-auto w-40 mb-3" alt="AYOS" />
        <p className="text-sm">
          Let&apos;s build a positive community together!
        </p>
        <div className="text-green-500">Log In</div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <FormPhoneNumber
            name="mobile_number"
            onChange={setForm}
            value={form.mobile_number}
            label="Mobile Number"
          />
        </div>
        <div className="mb-4">
          <FormInput
            name="password"
            onChange={setForm}
            value={form.password}
            label="Password"
            type="password"
          />
        </div>
        <div>
          <button
            className="btn primary w-full"
            type="submit"
            disabled={isLoading}
          >
            LOG IN
          </button>
        </div>
        <div className="text-center mt-4">
          <a
            className="text-sm underline text-primary-500"
            href="/"
            onClick={handleForgotPassword}
          >
            Forgot Password?
          </a>
        </div>
      </form>
    </div>
  );
}

LoginModal.defaultProps = {
  mobileNumber: '',
};

LoginModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  mobileNumber: PropTypes.string,
};

export default LoginModal;
