import { configureApi } from 'react-reqq';
import Cookie from 'js-cookie';
import { get } from 'lodash';
import { toastError } from 'react-geek-toast';
import history from './history';

const store = configureApi({
  endpoint: process.env.REACT_APP_END_POINT,
  requestHeaders: () => {
    const token = Cookie.get('token');
    if (!token) return {};
    return {
      Authorization: `Bearer ${token}`,
    };
  },
  timeout: 180000,
  cacheStorage: 'WEBSQL',
  onError: (err) => {
    const err_message =
      get(err, 'response.0.message') ||
      get(err, 'response.errors.0.detail') ||
      get(err, 'response.message') ||
      get(err, 'response.error.message');
    if (err.status === 400) {
      toastError(err_message || 'Unprocessable Entity!');
      return;
    }
    if (err.status === 401) {
      toastError(err_message || 'Unauthorized!');
      return;
    }

    if (err.status === 403) {
      toastError(err_message || 'Forbidden!');
      const logoutCondition = false; // TODO
      if (logoutCondition) history.push('/logout');
      return;
    }

    if (err.status === 404) {
      if ((err_message || '').indexOf('E_ROUTE_NOT_FOUND') > -1) {
        toastError('Not found!');
      }
      return;
    }
    if (err.status === 0) {
      toastError('Unable to connect to web service');
      return;
    }
    console.warn('unhandled error', err); // eslint-disable-line
  },
});

export default store;
