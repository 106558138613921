import React from 'react';
import { showModal } from 'modules/common/components/Modal';
import Cookie from 'js-cookie';
import store from 'setup/store';
import history from 'setup/history';
import LoginModal from './modals/LoginModal';
import RegisterModal from './modals/RegisterModal';
import OtpModal from './modals/OtpModal';
import { AUTHENTICATION } from './constants';

export const handleLogin = () => {
  showModal({
    content: (onClose) => <LoginModal onClose={onClose} />,
  });
};

export const handleRegister = () => {
  const validation = Cookie.get('register_validation_token');
  const mobile_number = Cookie.get('register_mobile_number');
  if (validation && mobile_number) {
    showModal({
      className: 'modal-sm',
      content: (close) => <OtpModal number={mobile_number} onClose={close} />,
    });
    return;
  }
  showModal({
    content: (onClose) => <RegisterModal onClose={onClose} />,
  });
};

export const handleLogout = () => {
  history.push('/logout');
};

export const authFunc = (func) => {
  const state = store.getState();
  const isAuthenticated = state?.api[AUTHENTICATION]?.isAuthenticated;
  if (!isAuthenticated) return handleLogin();
  return func();
};
