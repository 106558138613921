import React from 'react';
import logo from 'assets/images/logo.svg';
import banner from 'assets/images/coming-soon/banner-index.png';
// import FitContent from 'modules/common/components/FitContent';

function ComingSoon() {
  return (
    <>
      <div className="flex flex-1 lg:flex-row flex-col h-screen items-center bg-white">
        <div className="w-full md:max-w-lg">
          <div className="px-10 flex items-center">
            <div className="text-center max-w-lg mx-auto">
              <img
                src={logo}
                className="mx-auto w-32 sm:w-64 mb-5 mt-10"
                alt="AYOS"
              />
              <h3 className="text-3xl sm:text-4xl md:text-6xl lg:text-largest font-extrabold text-gray-800 uppercase leading-none mb-2 sm:mb-4">
                Coming Soon
              </h3>
              <p className="text-base sm:text-lg md:text-xl lg:text-2xl text-gray-800">
                Papunta palang tayo sa exciting na part!
              </p>
            </div>
          </div>
        </div>
        <div className="w-full h-full">
          <img
            src={banner}
            className="w-full h-full object-cover sm:object-left-top object-center"
            alt="Banner Coming Soon"
          />
        </div>
      </div>
    </>
  );
}

ComingSoon.propTypes = {};

export default ComingSoon;
