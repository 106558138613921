import React from 'react';
import PropTypes from 'prop-types';

const placeholder = 'https://via.placeholder.com/64x64/2C9B15/FFFFFF?text=JD';

function UserThumbnail({ src }) {
  const [img, setImg] = React.useState(src);
  return (
    <div className="relative h-8 w-8">
      <span className="bg-green-500 w-2 h-2 border border-white rounded-full absolute bottom-0 right-0 z-10" />
      <div className="bg-gray-500 rounded-full overflow-hidden">
        <img
          key={img}
          className="object-cover"
          src={img}
          onError={() => setImg(placeholder)}
          alt="alt"
        />
      </div>
    </div>
  );
}

UserThumbnail.defaultProps = {
  src: '',
};

UserThumbnail.propTypes = {
  src: PropTypes.string,
};

export default UserThumbnail;
