import React from 'react';
// import PropTypes from 'prop-types';

function Splash() {
  return (
    <div className="bg-white fixed h-full w-full top-0 left-0 flex">
      <div className="m-auto flex flex-col justify-center">
        <div className="mx-auto animate-pulse">Splash</div>
      </div>
    </div>
  );
}

Splash.propTypes = {};

export default Splash;
