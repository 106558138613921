import React, { Suspense, lazy } from 'react';
// import FitContent from 'modules/common/components/FitContent';
import Header from '../components/Header';

const AdsContainer = lazy(() => import('modules/ads/containers/AdsContainer'));
const FeedContainer = lazy(() =>
  import('modules/feed/containers/FeedContainer')
);
const SuggestionsContainer = lazy(() =>
  import('modules/suggestions/containers/SuggestionsContainer')
);

const Loader = () => <div />;

function Landing() {
  return (
    <>
      <div className="app-header">
        <Header />
      </div>
      <div className="app-body">
        <div className="my-container grid grid-cols-1 lg:grid-cols-7 h-full gap-4 pt-4">
          <div className="col-span-2 hidden lg:block">
            <Suspense fallback={<Loader />}>
              <div className="sticky-side">
                <SuggestionsContainer />
              </div>
            </Suspense>
          </div>
          <div className="col-span-3">
            <Suspense fallback={<Loader />}>
              <FeedContainer />
            </Suspense>
          </div>
          <div className="col-span-2 hidden lg:block">
            <Suspense fallback={<Loader />}>
              <div className="sticky-side">
                <AdsContainer />
              </div>
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
}

Landing.propTypes = {};

export default Landing;
